<template>
    <div class="btn-fixed-ct">
        <div class="btn-fixed btn-feedback" @click="goServiceChat">
            <i class="iconfont iconhuifu-line"></i>
            <span>问题<br>反馈</span>
        </div>
        <div class="btn-fixed btn-back-top" :style="backTopShow ? '' : 'visibility: hidden;'" @click="backTop">
            <i class="iconfont iconfanhuidingbu-line"></i>
            <span>回到<br>顶部</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'sideNavgation',
  data() {
    return {
      timer: null,
      backTopShow: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop > window.screen.availHeight * 2) return this.backTopShow = true
      this.backTopShow = false
    },
    backTop() {
      cancelAnimationFrame(this.timer)
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const startTime = +new Date()
      const animationTime = 400
      const that = this
      this.timer = requestAnimationFrame(function callBack() {
        const movingTime = animationTime - Math.max(0, startTime - (+new Date()) + animationTime)
        document.documentElement.scrollTop = document.body.scrollTop = movingTime * (-scrollTop) / animationTime + scrollTop
        that.timer = requestAnimationFrame(callBack)
        if (movingTime == animationTime) {
          cancelAnimationFrame(that.timer)
        }
      })
    },
    goServiceChat() {
      window._user_info.then(() => {
        location.href = `/messages?id=${this.$tool.ONLINE_SERVICE_CREADER_UID}`
      }).catch(() => {
        this.$modal.show('login1', { title: '登录后才能反馈问题呀' })
      })
    },
  }
}
</script>

<style scoped lang="less">
.btn-fixed-ct {
    display: block;
    position: fixed;
    right: 50%;
    margin-right: -600px;
    width: 50px;
    bottom: 205px;


    .btn-feedback {
    }

    .btn-back-top {
        margin-top: 10px;
    }

    .btn-fixed {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, .5);
        transition: background-color .2s ease-out;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 255, 255, 1);

            i {
                opacity: 0;
            }

            span {
                opacity: 1;
            }
        }

        i {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            bottom: 0;
            margin: 0;
            font-size: 28px;
            vertical-align: middle;
            text-align: center;
            color: rgba(0, 0, 0, .3);
            opacity: 1;
            transition: opacity .2s ease-out;
        }

        span {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            padding-top: 10px;
            padding-left: 2px;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 2px;
            color: #888;
            text-align: center;
            transition: opacity .2s ease-out;
        }
    }
}
</style>