<template>
    <div>
        <headerBar></headerBar>
        <div class="bgfff" style="margin-top:1px;">
            <div class="introduce-head">
                <p class="title">支持你喜爱的创作者</p>
                <div class="desc">
                    <p>冲呀是基于内容付费订阅的创作支持平台</p>
                    <p>· 连接创作者和核心粉丝 · 为创作者提高收入</p>
                </div>
                <div class="kol-welcome">
                    <div class="download">
                        <div class="qrcode-wrap">
                            <a href="/app">
                                <img src="https://static.chongyacdn.com/app/dl_app_client_qrcode.png" alt="客户端下载二维码">
                                <span>下载冲呀 App</span>
                            </a>
                        </div>
                        <img src="./static/man.png" class="man">
                    </div>
                    <div class="kols">
                        <img src="./static/kols.png" />
                    </div>
                </div>
            </div>
            <div class="who-use-box sect">
                <p class="why-title">谁在使用冲呀?</p>
                <div v-if="loadingVisible" class="loading-ani"></div>
                <div v-else class="bg-container" v-for="item in kols" :key="item.type">
                    <div class="who-use-item">
                        <div class="title-bar">
                            <div class="professional">{{item.type}}</div>
                            <div v-for="tag in item.tags" :key="tag" class="types">{{tag}}</div>
                        </div>
                        <div class="creator-list">
                            <div class="creator-item" v-for="creator in item.creators" :key="creator.id">
                                <img :src="$tool.resizeCdnImage(creator.avator)" @click="goKolHome(creator.uri)" />
                                <div class="desc">
                                    <div class="name">
                                        <span @click="goKolHome(creator.uri)">{{creator.nickname}}</span><span
                                        v-if="creator.authInfo" class="info"><i
                                        class="iconfont iconrenzheng-block icon-v"></i>{{creator.authInfo}}
                                        </span>
                                    </div>
                                    <div class="create-content">
                                        正在创作
                                        <br />
                                        {{creator.subject}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="why-use-box sect">
                <p class="why-title">为什么使用冲呀?</p>
                <div class="why-use-item">
                    <img class="img-duck" src="./static/duck.png" />
                    <div class="desc-container">
                        <p>持续稳定收入</p>
                        <p>创作者可以自定义个人会员模式，持续获得“付费订阅”的会员支持；可以单独设定作品价格，获得粉丝为“内容付费”的支持；还可以获得粉丝通过“支持一下”自由设定的金额支持。</p>
                    </div>
                </div>
                <div class="why-use-item">
                    <div class="desc-container">
                        <p>连接核心粉丝</p>
                        <p>“冲呀”平台不限流，创作者可以与核心粉丝自由交流。在其他平台宣传时，冲呀支持H5适配，粉丝可以流畅使用手机端完成作品浏览与付费订阅。</p>
                    </div>
                    <img class="img-connect" src="./static/connect.png" />
                </div>
                <div class="why-use-item">
                    <img class="img-pencil" src="./static/pencil.png" />
                    <div class="desc-container">
                        <p>分级内容管理</p>
                        <p>创作者可以同时创建多个主题，灵活设置付费档位。例：青铜会员可以每月查看最新作品；黄金会员可以获得每月发布作品的高清大图个人使用权限；铂金会员可以获得青铜、黄金会员权限，外加作品创作教程。设定付费档位之后，创作者也可以针对单篇内容设置具体解锁价格。</p>
                    </div>
                </div>
                <div class="why-use-item">
                    <div class="desc-container">
                        <p>内容安全保障</p>
                        <p>“冲呀”对设置过阅读权限的内容和附件，都启用了安全策略，数据和文件均进行加密存储，防止盗链和非法下载，每次访问都使用阿里云AK认证的身份校验机制确保访问安全。</p>
                    </div>
                    <img class="img-connect" src="./static/security.png" />
                </div>
            </div>
            <div class="use-step-box sect">
                <p class="why-title">使用冲呀的步骤?</p>
                <div class="use-step-item">
                    <div class="desc-container">
                        <p>创建创作者账号，设置付费等级</p>
                        <p style="font-size: 0;">
                            <span>注册后选择</span>
                            <a class="link-color-blue" href="/creator">成为创作者</a>
                            <span>，在“创作中心”中</span>
                            <a class="link-color-blue" href="/creator?tab=1">设置付费档位</a>
                            <span>，例如：5元/月可查看全部图片，10元/月可下载高清原图。</span>
                        </p>
                    </div>
                    <img class="img-create-setting" src="./static/create-setting.png" />
                </div>
                <div class="use-step-item">
                    <img class="img-select-publish" src="./static/select-publish.png" />
                    <div class="desc-container">
                        <p>发布内容，选择该内容的可见权限</p>
                        <p style="font-size: 0;">
                            <span>冲呀支持文字、图片、音频和视频的</span>
                            <a class="link-color-blue" href="/update/add">发布</a>
                            <span>，发布前可以选择内容的可见权限，例如：把权限勾选为订阅15元/月的粉丝才可见，就完成了内容的付费设置。</span>
                        </p>
                    </div>
                </div>
                <div class="use-step-item">
                    <div class="desc-container">
                        <p>向粉丝宣传，说明付费价值</p>
                        <p>发布成功后，创作者把内容链接或二维码发布到微信、微博、B站、豆瓣等平台告知粉丝，获得更多支持。</p>
                    </div>
                    <img class="img-spread" src="./static/spread.png" />
                </div>
            </div>
            <div class="product-mode-box sect">
                <p class="product-mode-title">冲呀的产品模式</p>
                <img class="img-product-mode" src="./static/product-mode.png" />
            </div>
            <div class="sect">
                <button
                    type="button"
                    class="btn-primary mt160"
                    @click="goCreator"
                    style="cursor: pointer; margin: 0 auto; display: block;"
                >成为创作者
                </button>
            </div>
            <div class="introduce-foot sect">
                <p class="title">联系我们</p>
                <p class="desc">创作者专属小助手，全程答疑协助</p>
                <div class="qrcode-container">
                    <div>
                        <img class="qrcode qq-qrcode" src="./static/qq-qrcode.png" />
                        <span>QQ 冲呀助手</span>
                    </div>
                    <div>
                        <img class="qrcode wechat-qrcode" src="./static/wechat-qrcode.png" />
                        <span>微信 冲呀助手</span>
                    </div>
                </div>
                <p class="more-contact">
                    <a href="/contact" class="link-color-gray2">更多联系方式</a>
                    <span class="arrow"></span>
                </p>
            </div>

            <side-navgation></side-navgation>
        </div>
        <footerBar></footerBar>
    </div>
</template>

<script>
import headerBar from "../components/headerBar/headerBar.vue";
import footerBar from "../components/footer/footer.vue";
import login from "../components/login";
import mainContent from "../components/content/mainContent.vue";
import left from "../components/content/leftBox.vue";
import right from "../components/content/rightBox.vue";
import cropper from "../components/cropper.vue";
import sideNavgation from "../components/content/sideNavgation"

export default {
    components: {
        headerBar,
        footerBar,
        login,
        mainContent,
        left,
        right,
        cropper,
        sideNavgation
    },
    data() {
        return {
            swiperOption: {
                speed: 1000,
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 20,
                autoplay: {
                    delay: 3000
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },
            swiperSlides: [1, 2, 3, 4, 5],
            kols: [],
            loadingVisible: true,
        };
    },
    created() {
        const convertData = (creatorIds, creators) => {
            return creatorIds
                .map(ele => creators.find(d => d.id === ele))
                .filter(ele => ele !== undefined);
        };
        this.$api.getStarCreators().then(({ data }) => {
            const { modules: m, creators } = data;
            const modules = JSON.parse(m);
            const temp = modules
                .map(ele => ({ ...ele, creators: convertData(ele.creators, creators) }))
                .filter(ele => ele.creators.length)
            this.kols = temp
        }).finally(() => this.loadingVisible = false);
    },
    mounted() {
        setInterval(() => {
            // console.log('simulate async data')
            if (this.swiperSlides.length < 10) {
                this.swiperSlides.push(this.swiperSlides.length + 1);
            }
        }, 3000);
    },
    methods: {
        callback() {
        },
        goCreator() {
            location.href = this.$cyuri.creator;
        },
        goKolHome(id) {
            location.href = `/@${id}`;
        },
    }
};
</script>

<style lang="less">
@import "../lib/general";

@background-yellow: rgba(252, 220, 44, 0.6);
@background-gray: #fafafa;
@color-blue: #57bce1;

.sect {
    overflow: auto;
    padding: 80px 0;
}

.color-blue {
    color: @color-blue;
}

.loading-ani {
    margin-bottom: 48px;
}

.bg-container {
    width: 100%;
    padding: 72px 0 54px;

    &:nth-child(2) {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &:nth-child(2n + 1) {
        background: @background-gray;

        .professional {
            &::after {
                border-right-color: @background-gray !important;
            }
        }
    }
}

.introduce-head {
    width: 100%;
    background-color: @background-yellow;
    padding-top: 56px;
    overflow: hidden;

    .title {
        font-size: 48px;
        font-weight: 500;
        line-height: 1.04;
        text-align: center;
        color: #333333;
    }

    .desc {
        font-size: 16px;
        line-height: 1.75;
        text-align: center;
        color: #444444;
        margin: 40px 0 27px;
    }

    .kol-welcome {
        position: relative;
        overflow: hidden;
        width: 828px;
        margin: 0 auto;

        .download {
            position: relative;

            .qrcode-wrap {
                position: absolute;
                z-index: 10;
                left: 0;
                top: 0;
                width: 96px;
                height: 112px;
                padding: 8px 8px 4px;
                border-radius: 0;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #fff7cf;

                img {
                    width: 80px;
                    height: 80px;
                }

                span {
                    font-size: 12px;
                    color: #666;
                    line-height: 1.33;
                }
            }

            .man {
                margin-top: 20px;
                margin-left: 80px;
                width: 79px;
                height: 92px;
            }
        }

        .kols {
            position: absolute;
            right: 0;
            bottom: -2px;

            > img {
                width: 532px;
                height: 100px;
            }
        }
    }
}

.product-mode-title {
    font-size: 26px;
    line-height: 1.15;
    text-align: center;
    color: #444444;
    margin-bottom: 48px;
}

.why-title {
    font-size: 34px;
    line-height: 1.12;
    color: #333333;
    padding-bottom: 48px;
    text-align: center;
    letter-spacing: 0.09px;
}

.why-use-box,
.product-mode-box {
    background: @background-gray;
}

.img-product-mode {
    width: 680px;
    height: 704px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

.why-use-item,
.use-step-item,
.who-use-item {
    width: 720px;
    margin: 0 auto 56px;
    display: flex;

    img {
        flex-shrink: 0;
        object-fit: contain;
    }

    .img-duck,
    .img-connect {
        margin: auto 94px;
    }

    .img-create-setting {
        width: 363px;
        height: 285px;
        margin: -15px -15px -15px 42px;
    }

    .img-select-publish {
        width: 345px;
        height: 281px;
        margin-right: 75px;
        margin: -15px 60px -15px -15px;
    }

    .img-spread {
        width: 349px;
        height: 281px;
        margin: -15px -15px -15px 56px;
    }

    .img-duck {
        width: 124px;
        height: 99px;
    }

    .img-connect {
        width: 124px;
        height: 110px;
    }

    .img-pencil {
        margin: auto 125px;
        width: 62px;
        height: 110px;
    }

    .desc-container {
        p {
            &:first-child {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
            }

            &:last-child {
                margin-top: 16px;
                font-size: 14px;
                line-height: 1.86;
                letter-spacing: 0.93px;
                color: #777777;

                span {
                    font-size: 14px;
                }

                a {
                    font-size: 14px;
                    color: @color-blue;
                }
            }
        }
    }
}

.why-use-item,
.use-step-item {
    &:last-child {
        margin-bottom: 0;
    }
}

.who-use-item {
    margin-bottom: 0;
}

.introduce-foot {
    width: 100%;
    background-color: @background-yellow;

    .title {
        font-size: 34px;
        text-align: center;
        color: #333333;
    }

    .desc {
        font-size: 16px;
        text-align: center;
        color: #333333;
        margin-top: 48px;
        margin-bottom: 24px;
    }

    .qrcode-container {
        width: 329px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        > div {
            width: 132px;

            > img {
                width: 100%;
            }

            > span {
                display: block;
                font-size: 13px;
                line-height: 1.85;
                letter-spacing: 0.87px;
                text-align: center;
                color: #333333;
            }
        }
    }

    .more-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        font-size: 14px;
        line-height: 1;
        color: #333333;

        a {
            display: inline-block;
            margin-right: 6px;
        }

        .arrow {
            display: block;
            width: 8px;
            height: 8px;
            transform: rotateZ(45deg);
            border-top: 1px solid #333333;
            border-right: 1px solid #333333;
        }

        a {
            &:hover {
                + span {
                    border-top: 1px solid #27a5d4;
                    border-right: 1px solid #27a5d4;
                }
            }
        }
    }
}

.who-use-box {
    .who-use-item {
        display: block;

        .title-bar {
            display: flex;
            align-items: center;

            > div {
                flex-shrink: 0;
            }

            .professional {
                padding: 7px 24px 7px 16px;
                border-radius: 2px;
                background-color: #f59639;
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                letter-spacing: 0.93px;
                color: #ffffff;
                position: relative;
                margin-right: 30px;

                &::after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-right: 13px solid #fff;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

            .types {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                line-height: 1.5;
                color: #444444;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 2px;
                    background: #f59639;
                    margin: 0 10px;
                }
            }
        }

        .creator-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 18px;

            .creator-item {
                width: 312px;
                display: flex;
                margin: 18px 0;

                > img {
                    width: 72px;
                    height: 72px;
                    display: inline-block;
                    margin-right: 16px;
                    border-radius: 8px;
                    cursor: pointer;
                }

                .name {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1;
                    color: #444444;

                    span {
                        &:first-child {
                            cursor: pointer;

                            &:hover {
                                color: #27a5d4;
                            }
                        }

                        &.info {
                            margin-left: 8px;
                            font-size: 13px;
                            font-weight: normal;
                            color: #666;
                        }
                    }

                    img {
                        margin-left: 8px;
                        width: 18px;
                        height: 18px;
                        border-radius: 8px;
                    }
                }

                .desc {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    flex-shrink: 1;
                }

                .create-content {
                    font-size: 14px;
                    line-height: 1.57;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

.btn-primary {
    .btn-color-normal();
    width: 190px;
    height: 48px;
    background: #fcdc2c;
    border: 0;
    border-radius: 24px;
    font-size: 22px;
    color: #000;
    outline: none;
}

.bgfff {
    background: #fff;
}

.swiper-button-next,
.swiper-button-prev {
    background-image: none;
    background-color: #fcdc2c;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    outline: none;

    i {
        font-size: 50px;
        color: #000;
    }

    &.swiper-button-disabled {
        opacity: 1;
        background-color: #efefef;

        i {
            color: #bbb;
        }
    }
}

.swiper-button-prev {
    left: -80px;
}

.swiper-button-next {
    right: -80px;
}
</style>
