<template>
    <div class="leftBox" :style="'width:'+w+'px'">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "leftBox",
  props: {
    w: {
      type: String,
      default: '696'
    }
  }
}
</script>

<style>
.leftBox {
    float: left;
    min-height: 1px; /*保证有内容高度，才能撑开内容宽度*/
}
</style>
