<template>
    <div :class="'main-box ' + ($tool.isLowBrowser()?'main-box-tip':'')">
        <slot></slot>
        <side-navgation></side-navgation>
    </div>
</template>

<script>
import sideNavgation from './sideNavgation'

export default {
  name: "mainContent",

  components: {
    sideNavgation,
  },

}
</script>

<style>
.main-box.main-box-tip {
    min-height: calc(100vh - 220px);
}

.main-box,
.hide-low-browser-tip .main-box {
    min-height: calc(100vh - 160px);
}

.main-box {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 1056px;
    margin: 0 auto;
    overflow: hidden;
}
</style>
