<template>
    <div class="rightBox" :style="'width:'+w+'px'">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: "rightBox",
  props: {
    w: {
      type: String,
      default: '336'
    }
  }
}
</script>

<style>
.rightBox {
    float: left;
    overflow: hidden;
    margin-left: 24px;
}
</style>
