import base from '../lib/main_base'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import App from './app'
let Vue = base.Vue
Vue.use(VueAwesomeSwiper)
new Vue({
  el: '#app',
  render: h => h(App)
})
